export const companiesEn = {
  // BEGIN Michael
  COMPANY: 'Company',
  COMPANIES: 'Companies',

  COMPANY_INFO: 'Company Info',
  COMPANY_NAME: 'Company Name',
  COMPANY_TYPE: 'Company Type',
  COMPANY_CODE: 'Company Code',
  COMPANY_UPDATE: 'Company Update',

  COMPANY_ADD_SUCCESS: 'Company added successfully',
  UPDATE_COMPANY_INFO: 'Update Company Info',
  COMPANY_UPDATE_SUCCESS: 'Company updated successfully',

  CREATE_COMPANY: 'Create Company',
  CREATE_AND_OTHER: 'Create Company & Create Other',

  LEARN_ABOUT: 'Learn About',

  CLIENT: 'Client',
  CLIENTS: 'Clients',

  CURRENCY: 'Currency',
  PHONE_ALTERNATE: 'Alternate Phone',
  EDI_ID: 'EDI ID',
  FED_TAX_NUMBER: 'Fed Tax Number',
  DUNS_NUMBER: 'DUNS Number',
  MC_NUMBER: 'MC Number',
  DOT_NUMBER: 'DOT Number',

  INELIGIBLE_DAYS: 'Ineligible Days',

  IDENTITY: 'Identity',
  UPDATE_IDENTITY_INFO: 'Update Identity Info',
  IDENTITY_INFO_UPDATED: 'Identity Info Updated Successfully',
  CREDIT_INFO_UPDATED: 'Credit Info Updated Successfully',

  CREDIT_RATE: 'Credit Rating',
  CREDIT_LIMIT_APPROVED: 'Credit Limit Approved',
  PAYMENT_TERM: 'Payment Term',
  PAYMENT_TERMS: 'Payment Terms',

  ALREADY_CONNECTED: 'Already Connected',
  UPDATE_RELATIONSHIP: 'Update Relationship',

  CONCENTRATION_LIMIT: 'Concentration Limit',

  DEBTOR: 'Debtor',
  DEBTORS: 'Debtors',

  DEBTOR_STATUS: 'Debtor Status',

  BUY: 'Buy',
  PAPERWORK_REQUIRED: 'Required Original Paperwork?',
  INVOICE_PREFERENCE: 'Invoice Preference',

  BASIC_DETAILS: 'Basic Details',
  OPERATIONAL_DETAILS: 'Operational Details',
  CONTACT_DETAILS: 'Contact Details',
  POINTS_OF_CONTACT: 'Points of Contact',
  PAYMENT_FEES: 'Payment Fees',
  ACH_FEE: 'ACH Fee',
  WIRE_FEE: 'Wire Fee',
  CHECK_FEE: 'Check Fee',
  FUEL_CARD_FEE: 'Fuel Card Fee',
  SAME_DAY_ACH_FEE: 'Same Day ACH Fee',

  FEES_UPDATED_SUCCESSFULLY: 'Fees updated successfully',
  NO_DEBTORS: 'No Debtors',
  NO_DEBTORS_WARNING_MESSAGE:
    'Currently, the client has no debtors available to make a new relationship. Please add at least one Debtor more to continue',
  ADD_COMPANY_CONTACT: 'Add Company Contact',
  EDIT_COMPANY_CONTACT: 'Edit Company Contact',
  ADDED_COMPANY_CONTACT_SUCCESSFULLY: 'Company Contact added successfully',
  UPDATED_COMPANY_CONTACT_SUCCESSFULLY: 'Company Contact updated successfully',
  DONT_HAVE_PERMISSION_TO_EDIT: "You don't have permission to edit. So, you only can view the information.",

  PORTFOLIO_INTEREST: 'Portfolio Interest',
  UPDATE_PORTFOLIO_INTEREST: 'Update Portfolio Interest',
  PORTFOLIO_INTEREST_UPDATED_SUCCESSFULLY: 'Portfolio Interest Updated Successfully',

  INTEREST_PRINCIPAL: 'Interest Principal',
  PRINCIPAL: 'Principal',
  INTEREST_TYPE: 'Interest Type',
  INTEREST_RATE: 'Interest Rate',
  FLOOR_MINIMUM: 'Floor/Minimum',
  INTEREST_RATE_MINIMUM: 'Interest Rate Minimum',
  INTEREST_DAYS_PER_YEAR: 'Interest Days Per Year',
  INTEREST_RATE_VALIDATION_FLAT_RATE:
    'Interest rate must be greater than 0 and less than or equal to 100, and the entered value should have a maximum of 2 decimal places.',
  INTEREST_RATE_VALIDATION: 'Interest Rate must be greater than or equal to 0 and less than or equal to 100',
  EIN_MUST_BE_MAX_11_DIGITS: 'EIN must be at most 11 digits',
  EIN_MUST_BE_MIN_9_DIGITS: 'EIN must be at least 9 digits',
  DEBTOR_RELATIONSHIP: 'Debtor Relationship',
  ADD_CHILDREN_DEBTORS: 'Add Children Debtors',
  ADD_CHILDREN: 'Add Children',
  IS_PARENT: 'Is Parent',
  NUMBER_OF_CHILDREN: 'Number of Children',
  REMOVE_CHILDREN_DEBTORS_CONFIRMATION: 'Are you sure you want to remove these children debtors?',
  CHILDREN_ADDED_SUCCESSFULLY: 'Children have been added successfully!',
  CHILDREN_REMOVED_SUCCESSFULLY: 'Children have been removed successfully!',
  NO_CHILDREN_MESSAGE: 'To add Debtors as Children, press the Add Children button.',
  CHILDREN_REPEAT: 'You have selected a repeated child debtor. Please remove it or select another option',
  VENDOR_DETAILS: 'Vendor Details',
  CLIENT_BROKER: 'Client / Broker',
  TOTAL_VENDOR_PAYMENT: 'Total Vendor Payment',
  CLEAR_ALL_REMIT_EMAILS: 'Clear All Remit Emails',
  ADD_NEW_REMIT_EMAIL: 'Add New Remit Email',
  CLEAR_ALL_REMIT_EMAILS_CONFIRMATION:
    'Are you sure you want to delete all remit emails? Please note that at least one remit email must remain',
  COMMISSION_BASED_ON: 'Commission Based On',
  SALES_COMMISSION_PERCENTAGE: 'Sales Commission Percentage',
  FEES_USED_FOR_COMMISSION: 'Fees used for Commission',
  PURCHASE_FEE: 'Purchase Fee',
  COLLECTION_FEE: 'Collection Fee',
  FEE_PER_INVOICE: 'Fee Per Invoice',
  INTEREST_RATE_FEE: 'Interest Rate Fee',
  ADDITIONAL_FEE: 'Additional Fee',
  FUEL_ADVANCE_FEE: 'Fuel Advance Fee',
  NEGATIVE_INTEREST_FEE: 'Negative Interest Fee',
  PAYMENT_FEE: 'Payment Fee',
  QP_FEE: 'QP Fee',
  RESERVE_RELEASE_FEE: 'Reserve Release Fee',
  OPEN_AND_CLOSED_INVOICE_FEES: 'Open and Closed Invoice Fees',
  CLOSED_INVOICE_FEES: 'Closed Invoice Fees',
  UPDATE_EMPLOYEE: 'Update Employee',
  EMPLOYEE_UPDATED_SUCCESSFULLY: 'Employee updated successfully',
  BROKER_CLIENTS: 'Broker Clients',
  CLIENT_BROKER_DEBTOR_RELATIONSHIP: 'Client, Broker, Debtor & Relationship',
  DEBTOR_NOTES_UPDATED_SUCCESSFULLY: 'Debtor Notes updated successfully',
  SET_RELATIONSHIP: 'Set Relationship',
  DEBTOR_LISTING: 'Debtor Listing',
  AUSTRALIAN_DOLLAR: '(AU$) Australian Dollar',
  // END Michael

  // BEGIN Fco
  RELATIONSHIPS: 'Relationships',

  ANALYSIS: 'Analysis',
  CREDIT_INFO: 'Credit Info',
  UPDATED_CREDIT_INFO: 'Updated Credit Info',

  SALES_REP: 'Sales Rep',
  FACTOR_BROKER: 'Factor Broker',
  BANK_RELATIONSHIP: 'Bank Relationship',

  NEW_RELATIONSHIP: 'New Relationship',
  BROKER: 'Broker',
  BROKERS: 'Brokers',

  CLIENT_ROLES: 'Client Roles',
  ADD_EMPLOYEE: 'Add Client Role',

  ACCOUNT_EXECUTIVE: 'Account Executive',
  COLLECTION_AGENT: 'Collection Agent',
  SALES_EXECUTIVE: 'Sales Executive',
  ADDED_CLIENT_ROLE_SUCCESSFULLY: 'Client Role added successfully',
  DELETED_CLIENT_ROLE_SUCCESSFULLY: 'Client Role deleted successfully',
  DELETE_CLIENT_ROLE_CONFIRMATION: 'Are you sure you want to delete this client role?',

  // END Fco

  // BEGIN Fabian
  ERROR: 'Error',
  NEW_CONNECTION_SUCCESSFULLY: 'New Connection added successfully',
  RELATIONSHIP_UPDATED_SUCCESSFULLY: 'Relationship updated successfully',
  COMPANY_NOT_VALID: 'The company is not a valid {companyType}',
  UNITED_STATES: 'United States',
  US_DOLLAR: '($) U.S. Dollar',
  CANADIAN_DOLLAR: '(Can$) Canadian dollar',
  EURO: '(€) Euro',
  BRITISH_POUND: '(£) British Pound',
  CHINESE_YUAN: '(¥) Chinese Yuan',
  NEED_ORIGINALS: 'Need Originals',
  NOA_EMAIL: 'NOA Email',
  DELIVERY_METHOD: 'Delivery Method',
  PORTAL: 'Portal',
  BILLING_EMAIL: 'Billing Emails',
  BILLING_INFORMATION_UPDATED_SUCCESSFULLY: 'Billing Information Updated Successfully',
  CLEAR_ALL_BILLING_EMAIL: 'Are you sure you want to delete all billing emails?',
  CLEAR_ALL_NOA_EMAIL: 'Are you sure you want to delete all NOA emails?',
  BILLING_INFORMATION: 'Billing Information',
  CLEAR_ALL_BILLING_EMAILS: 'Clear All Billing Emails',
  CLEAR_ALL_NOA_EMAILS: 'Clear All NOA Emails',
  ADD_NEW_BILLING_EMAIL: 'Add New Billing Email',
  SUBJECT: 'Subject',
  BODY: 'Body',
  SUBJECT_AND_BODY_FROM_FACTOR_TEMPLATE: 'Subject and Body from Factor Template',
  SUBJECT_FROM_FACTOR_TEMPLATE: 'Subject from Factor Template',
  BODY_FROM_FACTOR_TEMPLATE: 'Body from Factor Template',
  UPDATE_INVOICE_DELIVERY_SUCCESSFULLY: 'Invoice delivery template updated successfully',
  INVOICE_DELIVERY: 'Invoice Delivery',
  NEGATIVE_CASH_RESERVE_FEE: 'Negative Cash Reserve Fee',
  DOLLAR_AMOUNT: 'Dollar Amount',
  PERCENTAGE_AMOUNT: 'Percentage Amount',
  NEGATIVE_CASH_RESERVE_TYPE: 'Negative Cash Reserve Type',
  HAS_NEGATIVE_CASH_RESERVE: 'Has Negative Cash Reserve',
  FEE_IS_CHARGE_DAILY: 'Fee is charged daily',
  FIRST_PART_MESSAGE_NEGATIVE_CASH_RESERVE:
    ' The negative cash reserve fee is charged as follows, if the cash reserve is $-100, and the configuration is:',
  SECOND_PART_MESSAGE_NEGATIVE_CASH_RESERVE: '$50 Dollar amount.',
  THIRD_PART_MESSAGE_NEGATIVE_CASH_RESERVE: '10% Percentage amount.',
  FOURTH_PART_MESSAGE_NEGATIVE_CASH_RESERVE:
    'Then the commission will be $50 + 10%*$100 = $50 + $10 = $60. Giving a total of $-160.',
  MESSAGE_UPDATE_NOA:
    'You are going to update a NOA Document that is inactive. Please note that if you enable this, the current NOA will be deactivated.',
  FUEL_ADVANCE: 'Fuel Advance',
  ENABLE_FUEL_ADVANCE: 'Enable Fuel Advance',
  FUEL_ADVANCE_MAX_PERCENT: 'Fuel Advance Max Percent',
  FUEL_ADVANCE_MIN_FEE: 'Fuel Advance Min Fee',
  FUEL_ADVANCE_UPDATED_SUCCESSFULLY: 'Fuel Advance updated successfully',
  NOTES_BILLING_INFORMATION: 'Notes Billing Information',
  FUNDING_NOTES: 'Funding Notes',
  UPDATE_FUNDING_NOTES: 'Update Funding Notes',
  FUNDING_NOTES_UPDATED_SUCCESSFULLY: 'Funding Notes updated successfully',
  VENDOR_NAME: 'Vendor Name',
  VENDOR_PAID: 'Vendor Paid',
  VENDOR_ADVANCE_AMOUNT: 'Vendor Advance Amount',
  VENDOR_PAYMENT_AMOUNT: 'Vendor Payment Amount',
  VENDOR_INVOICE_ID: 'Vendor Invoice ID',
  QP_FEES: 'QP Fees',
  BROKER_PAYMENT_AMOUNT: 'Broker Payment Amount',
  VENDOR_PAYMENT: 'Vendor Payment Amount',
  BROKER_PAYMENT: 'Broker Payment',
  BROKER_NAME: 'Broker Name',
  MONEY_CODE_FEE: 'Money Code Fee',
  // END Fabian

  //BEGIN Esteban
  INVOICE_CLIENTS_DIFFERENTS: 'Approval for funding cannot be done because clients are different',
  GENERATE_INVOICE: 'Generate Invoice',
  ENABLE_GENERATE_INVOICE: 'Enable Generate Invoice',
  CLIENT_ID: 'Client ID',
  DEBTOR_ID: 'Debtor ID',
  BUY_STATUS: 'Buy Status',
  //END Esteban

  // BEGIN Patricio
  NOA_SETTING: 'NOA Setting',
  SEND_NOA: 'Send NOA',
  FIRST_NOA_SENT: 'First NOA Sent',
  LAST_NOA_SENT: 'Last NOA Sent',
  SEND_NOA_CONFIRMATION: 'Are you sure you want to send the NOA document to the selected list of debtors?',
  SEND_NOA_SUCCESS: 'NOA sent successfully',
  CLIENT_ACCRUED_INTEREST: 'Autocharge Accrued Interest',
  CLIENT_FRECUENCY_OF_COLLECTION: 'Frequency of Collection',
  CLIENT_SETTING_NOT_FOUND: 'Using default settings. ',
  AUTOCHARGE: 'Autocharge',
  FREQUENCY_OF_COLLECTION: 'Frequency',
  CLIENT_SETTING_OVERWRITE: 'The client configuration will overwrite the factor configuration.',
  VENDOR: 'Vendor',
  VENDORS: 'Vendors',
  STATEMENT_EMABLE: 'Statement Enable',
  STATEMENT_EMAILS: 'Statement Emails',
  STATEMENT_INFORMATION_UPDATED_SUCCESSFULLY: 'Statement Information Updated Successfully',
  CLEAR_ALL_STATEMENT_EMAILS: 'Clear All Statement Emails',
  ADD_NEW_STATEMENT_EMAIL: 'Add New Statement Email',
  DEBTOR_STATEMENTS: 'Debtor Statements',
  CANADA: 'Canada',
  MEXICO: 'México',
  CREDIT_LIMIT_RANGE: 'Credit Limit Range',
  // END Patricio

  COMPANIES_PERMISSION: 'Companies Permission',
  SEARCH_PERMISSIONS: 'Search Permissions',

  UPDATE_CREDIT_INFO: 'Update Credit Info',
  REQUEST_CREDIT_INFO_UPDATE: 'Request Credit Info Update',
  UPDATE_CREDIT_INFO_WARNING:
    'Updating this information will mark all pending internal credit update requests for this company as outdated',
}

export const companiesEs: typeof companiesEn = {
  // BEGIN Michael
  COMPANY: 'Compañía',
  COMPANIES: 'Compañías',

  COMPANY_INFO: 'Información de la Compañía',
  COMPANY_NAME: 'Nombre de la Compañía',
  COMPANY_TYPE: 'Tipo de Compañía',
  COMPANY_CODE: 'Código de Compañía',
  COMPANY_UPDATE: 'Actualización de Compañía',

  COMPANY_ADD_SUCCESS: 'Compañía agregada exitosamente',
  UPDATE_COMPANY_INFO: 'Actualizar Informacion de la Compañía',
  COMPANY_UPDATE_SUCCESS: 'Compañía actualizada exitosamente',

  CREATE_COMPANY: 'Crear Compañía',
  CREATE_AND_OTHER: 'Crear Compañía & Crear Otra',

  LEARN_ABOUT: 'Informate sobre las',

  CLIENT: 'Cliente',
  CLIENTS: 'Clientes',

  CURRENCY: 'Moneda',
  PHONE_ALTERNATE: 'Teléfono Alternativo',
  EDI_ID: 'ID EDI',
  FED_TAX_NUMBER: 'Número de Impuestos Federales',
  DUNS_NUMBER: 'Número DUNS',
  MC_NUMBER: 'Número MC',
  DOT_NUMBER: 'Número DOT',
  INELIGIBLE_DAYS: 'Días Inelegibles',

  IDENTITY: 'Identidad',
  UPDATE_IDENTITY_INFO: 'Actualizar Información de Identidad',
  IDENTITY_INFO_UPDATED: 'Información de Identidad actualizada exitosamente',
  CREDIT_INFO_UPDATED: 'Información de Crédito actualizada exitosamente',

  CREDIT_RATE: 'Tasa de Crédito',
  CREDIT_LIMIT_APPROVED: 'Límite de Crédito Aprobado',
  PAYMENT_TERM: 'Términos de Pago',
  PAYMENT_TERMS: 'Términos de Pago',

  ALREADY_CONNECTED: 'Ya Conectado',
  UPDATE_RELATIONSHIP: 'Actualizar Conexión',

  CONCENTRATION_LIMIT: 'Límite de Concentración',

  DEBTOR: 'Deudor',
  DEBTORS: 'Deudores',
  DEBTOR_STATUS: 'Estado del Deudor',

  BUY: 'Compra',
  PAPERWORK_REQUIRED: '¿Requiere Papeleria Original?',
  INVOICE_PREFERENCE: 'Preferencia de Factura',

  BASIC_DETAILS: 'Detalles Básicos',
  OPERATIONAL_DETAILS: 'Detalles Operativos',
  CONTACT_DETAILS: 'Detalles de Contacto',
  POINTS_OF_CONTACT: 'Puntos de Contacto',
  PAYMENT_FEES: 'Tarifas de Pago',
  ACH_FEE: 'Tarifa ACH',
  WIRE_FEE: 'Tarifa Wire',
  CHECK_FEE: 'Tarifa Check',
  FUEL_CARD_FEE: 'Tarifa Fuel Card',
  SAME_DAY_ACH_FEE: 'Tarifa Same Day ACH',

  FEES_UPDATED_SUCCESSFULLY: 'Tasas actualizadas correctamente',
  NO_DEBTORS: 'No hay Deudores',
  NO_DEBTORS_WARNING_MESSAGE:
    'Actualmente, el cliente no tiene deudores disponibles para hacer una nueva relación. Por favor agregue al menos un Deudor más para continuar',
  ADD_COMPANY_CONTACT: 'Añadir Contacto de Compañía',
  EDIT_COMPANY_CONTACT: 'Editar Contacto de Compañía',
  ADDED_COMPANY_CONTACT_SUCCESSFULLY: 'Contacto de Compañía agregado exitosamente',
  UPDATED_COMPANY_CONTACT_SUCCESSFULLY: 'Contacto de Compañía actualizado exitosamente',
  DONT_HAVE_PERMISSION_TO_EDIT: 'No tienes permiso para editar. Por lo tanto, solo puede ver la información.',

  PORTFOLIO_INTEREST: 'Interés de la Cartera',
  UPDATE_PORTFOLIO_INTEREST: 'Actualizar Interés de la Cartera',
  PORTFOLIO_INTEREST_UPDATED_SUCCESSFULLY: 'Interés de la Cartera actualizado exitosamente',

  INTEREST_PRINCIPAL: 'Interés Principal',
  PRINCIPAL: 'Principal',
  INTEREST_TYPE: 'Tipo de Interés',
  INTEREST_RATE: 'Tasa de Interés',
  INTEREST_RATE_MINIMUM: 'Tasa de Interés Mínima',
  FLOOR_MINIMUM: 'Piso/Mínimo',
  INTEREST_DAYS_PER_YEAR: 'Días de Interés por Año',
  INTEREST_RATE_VALIDATION_FLAT_RATE: 'La Tasa de Interés debe ser mayor que 0 y menor o igual a 100',
  INTEREST_RATE_VALIDATION: 'La Tasa de Interés debe ser mayor o igual a 0 y menor o igual a 100',
  EIN_MUST_BE_MAX_11_DIGITS: 'EIN debe tener como máximo 11 dígitos',
  EIN_MUST_BE_MIN_9_DIGITS: 'EIN debe tener al menos 9 dígitos',
  DEBTOR_RELATIONSHIP: 'Relación de Deudor',
  ADD_CHILDREN_DEBTORS: 'Agregar Deudores Hijos',
  REMOVE_CHILDREN_DEBTORS_CONFIRMATION: '¿Estás seguro de que quieres eliminar estos deudores hijos?',
  ADD_CHILDREN: 'Agregar Hijos',
  IS_PARENT: 'Es Padre',
  NUMBER_OF_CHILDREN: 'Número de Hijos',
  CHILDREN_REMOVED_SUCCESSFULLY: '¡Los hijos han sido eliminados correctamente!',
  CHILDREN_ADDED_SUCCESSFULLY: '¡Los hijos han sido agregados correctamente!',
  NO_CHILDREN_MESSAGE: 'Para agregar Deudores como Hijos, presione el botón Agregar Hijos.',
  CHILDREN_REPEAT: 'Ha seleccionado un deudor hijo repetido. Por favor, elimínelo o seleccione otra opción',
  VENDOR_DETAILS: 'Detalles del Proveedor',
  CLIENT_BROKER: 'Cliente / Corredor',
  TOTAL_VENDOR_PAYMENT: 'Pago Total del Proveedor',
  BROKER_NAME: 'Nombre del Corredor',
  CLEAR_ALL_REMIT_EMAILS: 'Borrar todos los correos electrónicos de remisión',
  ADD_NEW_REMIT_EMAIL: 'Agregar nuevo correo electrónico de remisión',
  CLEAR_ALL_REMIT_EMAILS_CONFIRMATION:
    '¿Estás seguro de que quieres borrar todos los correos electrónicos de remisión?. Tenga en cuenta que al menos debe haber un correo electrónico de remisión.',
  COMMISSION_BASED_ON: 'Comisión Basada En',
  SALES_COMMISSION_PERCENTAGE: 'Porcentaje de Comisión de Ventas',
  FEES_USED_FOR_COMMISSION: 'Tarifas utilizadas para la Comisión',
  PURCHASE_FEE: 'Tarifa de Compra',
  COLLECTION_FEE: 'Tarifa de Cobranza',
  FEE_PER_INVOICE: 'Tarifa por Factura',
  INTEREST_RATE_FEE: 'Tarifa de Tasa de Interés',
  ADDITIONAL_FEE: 'Tarifa Adicional',
  FUEL_ADVANCE_FEE: 'Tarifa de Anticipo de Combustible',
  NEGATIVE_INTEREST_FEE: 'Tarifa de Interés Negativo',
  PAYMENT_FEE: 'Tarifa de Pago',
  QP_FEE: 'Tarifa QP',
  RESERVE_RELEASE_FEE: 'Tarifa de Liberación de Reserva',
  OPEN_AND_CLOSED_INVOICE_FEES: 'Tarifas de Facturas Abiertas y Cerradas',
  CLOSED_INVOICE_FEES: 'Tarifas de Facturas Cerradas',
  UPDATE_EMPLOYEE: 'Actualizar Empleado',
  EMPLOYEE_UPDATED_SUCCESSFULLY: 'Empleado actualizado exitosamente',
  BROKER_CLIENTS: 'Clientes de Corredores',
  CLIENT_BROKER_DEBTOR_RELATIONSHIP: 'Cliente, Corredor, Deudor y Relación',
  DEBTOR_NOTES_UPDATED_SUCCESSFULLY: 'Notas de Deudor actualizadas exitosamente',
  SET_RELATIONSHIP: 'Establecer Relación',
  DEBTOR_LISTING: 'Listado de Deudores',
  AUSTRALIAN_DOLLAR: '(AU$) Dólar Australiano',
  // END Michael

  // BEGIN Fco
  RELATIONSHIPS: 'Conexiones',

  ANALYSIS: 'Análisis',
  CREDIT_INFO: 'Información de Crédito',
  UPDATED_CREDIT_INFO: 'Información de Crédito Actualizada',

  SALES_REP: 'Rep. Ventas',
  FACTOR_BROKER: 'Corredor de Factor',
  BANK_RELATIONSHIP: 'Conexiones Bancarias',

  NEW_RELATIONSHIP: 'Nueva Conexión',
  BROKER: 'Corredor',
  BROKERS: 'Corredores',

  CLIENT_ROLES: 'Roles de Cliente',
  ADD_EMPLOYEE: 'Agregar Rol de Cliente',

  ACCOUNT_EXECUTIVE: 'Ejecutivo de Cuenta',
  COLLECTION_AGENT: 'Agente de Cobranza',
  SALES_EXECUTIVE: 'Ejecutivo de Ventas',

  ADDED_CLIENT_ROLE_SUCCESSFULLY: 'Empleado de Compañía agregado exitosamente',
  DELETED_CLIENT_ROLE_SUCCESSFULLY: 'Empleado de Compañía eliminado exitosamente',
  DELETE_CLIENT_ROLE_CONFIRMATION: '¿Estás seguro de que quieres eliminar este empleado de compañía?',

  // END Fco

  // BEGIN Fabian
  ERROR: 'Error',
  NEW_CONNECTION_SUCCESSFULLY: 'Nueva Conexión agregada exitosamente',
  RELATIONSHIP_UPDATED_SUCCESSFULLY: 'Conexiones actualizadas exitosamente',
  COMPANY_NOT_VALID: 'La compañía no es válida {companyType}',
  UNITED_STATES: 'Estados Unidos',
  US_DOLLAR: '($) Dólar Estadounidense',
  CANADIAN_DOLLAR: '(Can$) Dólar Canadiense',
  EURO: '(€) Euro',
  BRITISH_POUND: '(£) Libra Esterlina',
  CHINESE_YUAN: '(¥) Yuan Chino',
  NEED_ORIGINALS: 'Necesita Originales',
  NOA_EMAIL: 'NOA Email',
  DELIVERY_METHOD: 'Método de Entrega',
  PORTAL: 'Portal',
  BILLING_EMAIL: 'Correos de Facturación',
  BILLING_INFORMATION_UPDATED_SUCCESSFULLY: 'Información de facturación actualizada correctamente',
  CLEAR_ALL_BILLING_EMAIL: '¿Estás seguro de que quieres borrar todos los correos de facturación?',
  CLEAR_ALL_NOA_EMAIL: '¿Estás seguro de que quieres borrar todos los correos de NOA?',
  CLEAR_ALL_NOA_EMAILS: 'Borrar todos los correos de NOA',
  BILLING_INFORMATION: 'Información de facturación',
  CLEAR_ALL_BILLING_EMAILS: 'Borrar todos los correos de facturación',
  ADD_NEW_BILLING_EMAIL: 'Agregar nuevo correo de facturación',
  SUBJECT: 'Asunto',
  BODY: 'Cuerpo',
  SUBJECT_AND_BODY_FROM_FACTOR_TEMPLATE: 'Asunto y Cuerpo de la Plantilla de Factor',
  SUBJECT_FROM_FACTOR_TEMPLATE: 'Asunto de la Plantilla de Factor',
  BODY_FROM_FACTOR_TEMPLATE: 'Cuerpo de la Plantilla de Factor',
  UPDATE_INVOICE_DELIVERY_SUCCESSFULLY: 'Plantilla de entrega de factura actualizada correctamente',
  INVOICE_DELIVERY: 'Entrega de Factura',
  NEGATIVE_CASH_RESERVE_FEE: 'Tarifa de Reserva de Efectivo Negativa',
  DOLLAR_AMOUNT: 'Monto en Dólares',
  PERCENTAGE_AMOUNT: 'Monto en Porcentaje',
  NEGATIVE_CASH_RESERVE_TYPE: 'Tipo de Reserva de Efectivo Negativa',
  HAS_NEGATIVE_CASH_RESERVE: 'Tiene Reserva de Efectivo Negativa',
  FEE_IS_CHARGE_DAILY: 'La tarifa se cobra diariamente',
  FIRST_PART_MESSAGE_NEGATIVE_CASH_RESERVE:
    ' La tarifa de reserva de efectivo negativa se cobra de la siguiente manera, si la reserva de efectivo es de $-100, y la configuración es:',
  SECOND_PART_MESSAGE_NEGATIVE_CASH_RESERVE: '$50 Monto en Dólares.',
  THIRD_PART_MESSAGE_NEGATIVE_CASH_RESERVE: '10% Monto en Porcentaje.',
  FOURTH_PART_MESSAGE_NEGATIVE_CASH_RESERVE:
    'Entonces la comisión será de $50 + 10%*$100 = $50 + $10 = $60. Dando un total de $-160.',
  MESSAGE_UPDATE_NOA:
    'Vas a actualizar un Documento NOA que está inactivo. Por favor tenga en cuenta que si lo habilita, el NOA actual se desactivará.',
  FUEL_ADVANCE: 'Anticipo de Combustible',
  ENABLE_FUEL_ADVANCE: 'Habilitar Anticipo de Combustible',
  FUEL_ADVANCE_MAX_PERCENT: 'Porcentaje Máximo de Anticipo de Combustible',
  FUEL_ADVANCE_MIN_FEE: 'Tarifa Mínima de Anticipo de Combustible',
  FUEL_ADVANCE_UPDATED_SUCCESSFULLY: 'Anticipo de Combustible actualizado correctamente',
  NOTES_BILLING_INFORMATION: 'Notas Información de Facturación',
  FUNDING_NOTES: 'Notas de Financiamiento',
  UPDATE_FUNDING_NOTES: 'Actualizar Notas de Financiamiento',
  FUNDING_NOTES_UPDATED_SUCCESSFULLY: 'Notas de Financiamiento actualizadas correctamente',
  VENDOR_NAME: 'Nombre del Proveedor',
  VENDOR_PAID: 'Proveedor Pagado',
  VENDOR_ADVANCE_AMOUNT: 'Monto de Anticipo del Proveedor',
  VENDOR_PAYMENT_AMOUNT: 'Monto de Pago del Proveedor',
  VENDOR_INVOICE_ID: 'ID de Factura del Proveedor',
  QP_FEES: 'Tarifas de QP',
  BROKER_PAYMENT_AMOUNT: 'Monto de Pago del Corredor',
  VENDOR_PAYMENT: 'Monto de Pago del Proveedor',
  BROKER_PAYMENT: 'Pago del Corredor',
  MONEY_CODE_FEE: 'Tarifa Money Code',
  // END Fabian

  //BEGIN Esteban
  INVOICE_CLIENTS_DIFFERENTS: 'No se puede aprobar la financiación porque los clientes son diferentes',
  GENERATE_INVOICE: 'Generar Factura',
  ENABLE_GENERATE_INVOICE: 'Habilitar Generar Factura',
  CLIENT_ID: 'ID de Cliente',
  DEBTOR_ID: 'ID de Deudor',
  BUY_STATUS: 'Estado de Compra',
  //END Esteban

  // BEGIN Patricio
  NOA_SETTING: 'Configuración de NOA',
  SEND_NOA: 'Enviar NOA',
  FIRST_NOA_SENT: 'Primer NOA Enviado',
  LAST_NOA_SENT: 'Último NOA Enviado',
  SEND_NOA_CONFIRMATION: '¿Estás seguro de que quieres enviar el documento NOA a la lista seleccionada de deudores?',
  SEND_NOA_SUCCESS: 'NOA enviado exitosamente',
  CLIENT_ACCRUED_INTEREST: 'Intereses devengados de autocarga',
  CLIENT_FRECUENCY_OF_COLLECTION: 'Frecuencia de Cobro',
  CLIENT_SETTING_NOT_FOUND:
    'Configuración de Cliente no encontrada. Los siguientes valores se extrajeron de la configuración del factor.',
  AUTOCHARGE: 'Autocarga',
  FREQUENCY_OF_COLLECTION: 'Frecuencia',
  CLIENT_SETTING_OVERWRITE: 'La configuración del cliente sobrescribirá la configuración del factor.',
  VENDOR: 'Proveedor',
  VENDORS: 'Proveedores',
  STATEMENT_EMABLE: 'Habilitar Estado',
  STATEMENT_EMAILS: 'Correos Electrónicos de Estado',
  STATEMENT_INFORMATION_UPDATED_SUCCESSFULLY: 'Información de Estado actualizada correctamente',
  CLEAR_ALL_STATEMENT_EMAILS: 'Borrar todos los correos electrónicos de estado',
  ADD_NEW_STATEMENT_EMAIL: 'Agregar nuevo correo electrónico de estado',
  DEBTOR_STATEMENTS: 'Estados de Deudor',
  CANADA: 'Canadá',
  MEXICO: 'México',
  CREDIT_LIMIT_RANGE: 'Rango de Límite de Crédito',
  // END Patricio

  COMPANIES_PERMISSION: 'Permisos de Compañías',
  SEARCH_PERMISSIONS: 'Permisos de búsqueda',

  UPDATE_CREDIT_INFO: 'Actualizar Información de Crédito',
  REQUEST_CREDIT_INFO_UPDATE: 'Solicitar Actualización de Información de Crédito',
  UPDATE_CREDIT_INFO_WARNING:
    'Actualizar esta información marcará todas las solicitudes de actualización de crédito internas pendientes para esta compañía como desactualizadas',
}
