export const factorsEn = {
  // BEGIN Michael
  FACTOR: 'Factor',
  FACTORS: 'Factors',
  FACTOR_ACCOUNT_CREATED_SUCCESSFULLY: 'Factor account created successfully!',
  UPDATE_FACTOR_INFORMATION: 'Update Factor Information',
  FACTOR_INFORMATION_UPDATED: 'Factor Information updated successfully!',
  BILLING_TEMPLATE: 'Billing Template',
  BILLING_TEMPLATE_DESCRIPTION: 'Email template for sending e-mails to debtors.',
  CONTACT_INFORMATION: 'Contact Information',
  CONTACT_PHONE: 'Contact Phone',
  CONTACT_EMAIL: 'Contact Email',
  CONTACT_INFORMATION_UPDATED: 'Contact Information updated successfully!',
  UPDATE_CONTACT_INFORMATION: 'Update Contact Information',
  SYSTEM_DEFAULTS: 'System Defaults',
  VENDOR_DEFAULTS_VALUES: 'Vendor Default Values',
  VENDOR_DEFAULTS_VALUES_UPDATED: 'Vendor Default Values updated successfully!',
  DEBTOR_DEFAULTS_VALUES: 'Debtor Default Values',
  DEBTOR_DEFAULTS_VALUES_UPDATED: 'Debtor Default Values updated successfully!',
  CLIENT_DEFAULTS_VALUES: 'Client Default Values',
  CLIENT_DEFAULTS_VALUES_UPDATED: 'Client Default Values updated successfully!',
  BROKER_DEFAULT_VALUES: 'Broker Default Values',
  BROKER_DEFAULT_VALUES_UPDATED: 'Broker Default Values updated successfully!',
  RELATIONSHIP_DEFAULT_VALUES: 'Relationship Default Values',
  RELATIONSHIP_DEFAULT_VALUES_UPDATED: 'Relationship Default Values updated successfully!',
  CURRENCY_SYMBOL: 'Currency Symbol',
  CURRENCY_SYMBOL_DESCRIPTION: 'Enable or disable Currency Symbol',
  // END Michael
  // BEGIN Fco
  BROKER_MOD: 'Broker Mod',
  FACTOR_ENABLE_BROKER_MOD: 'Enable or disable Broker Mod',
  FACTOR_GROUP_SAME_DAY_INVOICES_DESC: 'Merge Invoices from the same day into one payment batch',
  // END Fco
  // BEGIN Fabian
  ACTIVATED_FACTOR: 'Activated Factor',
  DESACTIVATED_FACTOR: 'Desactivated Factor',
  ACTIVATED: 'Activated',
  DESACTIVATED: 'Desactivated',
  UPDATED: 'Updated',
  CREATED: 'Created',
  FACTOR_ACTIVATED_SUCCESSFULLY: 'Factor Activated Successfully!',
  FACTOR_DESACTIVATED_SUCCESSFULLY: 'Factor Deactivated Successfully!',
  UPDATE_FACTOR: 'Update Factor',
  CREATE_FACTOR: 'Create Factor',
  CONFIRM_ACTIVATE_FACTOR: 'Are you sure would you like to activate factor from inactive status?',
  CONFIRM_DESACTIVATE_FACTOR: 'Are you sure would you like to deactivate factor from active status?',
  EMAIL_TEMPLATE_ALERT_INFORMATION: 'The variables that can be used in the subject and body can be the following:',
  CLIENT_NAME: 'Client Name',
  DEBTOR_NAME: 'Debtor Name',
  EMAIL_TEMPLATES: 'Email Templates',
  DEFAULT_NOA_TEMPLATE: 'NOA Template',
  DEFAULT_NOA_TEMPLATE_DESCRIPTION: 'NOA template for sending e-mails to debtors.',
  PAYMENT_ACCOUNTS: 'Payment Accounts',
  CREATE_PAYMENT_ACCOUNT: 'Create Payment Account',
  EDIT_PAYMENT_ACCOUNT: 'Edit Payment Account',
  EDIT_PAYMENT_ACCOUNT_SUCCESS: 'Payment Account edited successfully',
  DELETE_PAYMENT_ACCOUNT: 'Are you sure you want to delete this payment account?',
  DELETE_PAYMENT_ACCOUNT_SUCCESS: 'Payment Account deleted successfully',
  // END Fabian

  // BEGIN Patricio
  FACTOR_ACCRUED_INTEREST: 'Autocharge Accrued Interest',
  FACTOR_ACRRUED_FEES_FRECUENCY: 'Accrued Fees Frecuency',
  PAYMENT_BATCH_CODE: 'Payment Batch Code',
  DEFAULT_REMITTANCE_CLIENT_TEMPLATE: 'Remittance Client Template',
  DEFAULT_REMITTANCE_TEMPLATE_DESCRIPTION: 'Remittance template for sending e-mails to clients.',
  DEFAULT_STATEMENT_TEMPLATE: 'Statement Template',
  DEFAULT_STATEMENT_TEMPLATE_DESCRIPTION: 'Email template for Debtor Statements.',
  DEFAULT_REMITTANCE_BROKER_TEMPLATE: 'Remittance Broker Template',
  DEFAULT_REMITTANCE_BROKER_TEMPLATE_DESCRIPTION: 'Remittance template for sending e-mails to brokers.',
  DEFAULT_REMITTANCE_VENDOR_TEMPLATE: 'Remittance Vendor Template',
  DEFAULT_REMITTANCE_VENDOR_TEMPLATE_DESCRIPTION: 'Remittance template for sending e-mails to vendors.',

  EMAIL_CC_BCC: 'Email CC/BCC',
  TITLE_CC_BCC_SECTION: 'Create CC/BCC',
  TITLE_CREATE_CC_BCC: 'Create New',
  TITLE_EDIT_CC_BCC: 'Edit CC/BCC',

  DELETE_CC_BBC_EMAIL: 'Are you sure you want to delete this email?',
  DELETE_CC_BCC_EMAIL_SUCCESS: 'Email deleted successfully',
  CREATE_CC_BCC_EMAIL_SUCCESS: 'Email created successfully',
  EDIT_CC_BCC_EMAIL_SUCCESS: 'Email edited successfully',

  EMAIL_CC: 'CC',
  TOOLTIP_EDIT_EMAIL_CC: 'Edit Email CC',
  TOOLTIP_DELETE_EMAIL_CC: 'Delete Email CC',

  EMAIL_BCC: 'BCC',
  TOOLTIP_EDIT_EMAIL_BCC: 'Edit Email BCC',
  TOOLTIP_DELETE_EMAIL_BCC: 'Delete Email BCC',

  UPDATE_NOA_INVOICE_LEVEL: 'Update NOA Invoice Level Configuration',
  NOA_INVOICE_LEVEL_SETTING: 'NOA Invoice Level Setting',
  NOA_INVOICE_LEVEL_INFORMATION: 'NOA Level Information',
  NOA_INVOICE_LEVEL_FACTOR_SIGNATURE: 'Factor Signature',
  NOA_INVOICE_LEVEL_LEGAL_NOTICE: 'Legal Notice',
  NOA_INVOICE_IMAGE_SIGNATURE_INFO: 'You can upload your Signature as Image or Text',
  NO_SIGNATURE_AVAILABLE: 'No signature available',
  IMAGE_SIGNATURE: 'Image Signature',

  NOA_LEVEL: 'NOA Level',
  CLIENT_LEVEL: 'Client Level',
  INVOICE_LEVEL: 'Invoice Level',
  NOA_LEVEL_SETTING_TITLE: 'NOA Level Setting',
  NOA_LEVEL_SETTING_DESCRIPTION: 'Set the default NOA setting',
  NOA_INVOICE_LEVEL_FACTOR_CONFIGURATION_UPDATED_SUCCESSFULLY: 'NOA Invoice Level Configuration updated successfully!',
  FACTOR_INFORMATION: 'Factor Information',
  // END Patricio
  //BEGIN Esteban
  FACTOR_FUEL_ADVANCE: 'Enable Fuel Advance',
  FACTOR_INTEREST_RATE_PRIME: 'Prime Rate',
  FACTOR_INTEREST_RATE_SOFR: 'Sofr Rate',
  FACTOR_INTEREST_RATE_LIBOR: 'Libor Rate',
  FACTOR_TIMEZONE: 'Factor Timezone',
  FACTOR_TIMEZONE_DESCRIPTION: 'Factor Timezone Configuration',
  FACTOR_GROUP_SAME_DAY_INVOICES: 'Group Same Day Invoices',
  LEDGER_EXPORT_NAME: 'Ledger Export Name',
  FACTOR_ID: 'Factor ID',
  USER_ID: 'User ID',
  FIRTS_NAME: 'First Name',
  MIDDLE_NAME: 'Middle Name',
  LAST_NAME: 'Last Name',

  //END Esteban
  FACTOR_ACCRUED_INTEREST_DESCRIPTION: 'Enable or disable Autocharge Accrued Interest',
}

export const factorsEs: typeof factorsEn = {
  // BEGIN Michael
  FACTOR: 'Factor',
  FACTORS: 'Factores',
  FACTOR_ACCOUNT_CREATED_SUCCESSFULLY: '¡Cuenta de factor creada con éxito!',
  UPDATE_FACTOR_INFORMATION: 'Actualizar información del factor',
  FACTOR_INFORMATION_UPDATED: '¡Información del factor actualizada con éxito!',
  BILLING_TEMPLATE: 'Plantilla de facturación',
  BILLING_TEMPLATE_DESCRIPTION: 'Plantilla de correo electrónico para enviar correos electrónicos a los deudores.',
  CONTACT_INFORMATION: 'Información de contacto',
  CONTACT_PHONE: 'Teléfono de contacto',
  CONTACT_EMAIL: 'Correo electrónico de contacto',
  CONTACT_INFORMATION_UPDATED: '¡Información de contacto actualizada con éxito!',
  UPDATE_CONTACT_INFORMATION: 'Actualizar información de contacto',
  SYSTEM_DEFAULTS: 'Valores predeterminados del sistema',
  VENDOR_DEFAULTS_VALUES: 'Valores predeterminados del proveedor',
  VENDOR_DEFAULTS_VALUES_UPDATED: '¡Valores predeterminados del proveedor actualizados con éxito!',
  DEBTOR_DEFAULTS_VALUES: 'Valores predeterminados del deudor',
  DEBTOR_DEFAULTS_VALUES_UPDATED: '¡Valores predeterminados del deudor actualizados con éxito!',
  CLIENT_DEFAULTS_VALUES: 'Valores predeterminados del cliente',
  CLIENT_DEFAULTS_VALUES_UPDATED: '¡Valores predeterminados del cliente actualizados con éxito!',
  BROKER_DEFAULT_VALUES: 'Valores predeterminados del corredor',
  BROKER_DEFAULT_VALUES_UPDATED: '¡Valores predeterminados del corredor actualizados con éxito!',
  RELATIONSHIP_DEFAULT_VALUES: 'Valores predeterminados de la relación',
  RELATIONSHIP_DEFAULT_VALUES_UPDATED: '¡Valores predeterminados de la relación actualizados con éxito!',
  CURRENCY_SYMBOL: 'Símbolo de divisa',
  CURRENCY_SYMBOL_DESCRIPTION: 'Habilitar o deshabilitar el símbolo de divisa',
  // END Michael
  // BEGIN Fco
  BROKER_MOD: 'Broker Mod',
  FACTOR_ENABLE_BROKER_MOD: 'Habilitar o deshabilitar Broker Mod',
  FACTOR_GROUP_SAME_DAY_INVOICES_DESC: 'Agrupar facturas del mismo día en un lote de pagos',
  // END Fco
  // BEGIN Fabian
  ACTIVATED_FACTOR: 'Activar Factor',
  DESACTIVATED_FACTOR: 'Desactivar Factor',
  ACTIVATED: 'Activado',
  DESACTIVATED: 'Desactivado',
  UPDATED: 'Actualizado',
  CREATED: 'Creado',
  FACTOR_ACTIVATED_SUCCESSFULLY: '¡Factor activado con éxito!',
  FACTOR_DESACTIVATED_SUCCESSFULLY: '¡Factor desactivado con éxito!',
  UPDATE_FACTOR: 'Actualizar Factor',
  CREATE_FACTOR: 'Crear Factor',
  CONFIRM_ACTIVATE_FACTOR: '¿Está seguro de que desea activar el factor desde el estado inactivo?',
  CONFIRM_DESACTIVATE_FACTOR: '¿Está seguro de que desea desactivar el factor desde el estado activo?',
  EMAIL_TEMPLATE_ALERT_INFORMATION:
    'Las variables que se pueden utilizar en el asunto y el cuerpo pueden ser las siguientes:',
  CLIENT_NAME: 'Nombre del cliente',
  DEBTOR_NAME: 'Nombre del deudor',
  EMAIL_TEMPLATES: 'Plantillas de correo electrónico',
  DEFAULT_NOA_TEMPLATE: 'Plantilla NOA',
  DEFAULT_NOA_TEMPLATE_DESCRIPTION: 'NOA plantilla para enviar correos electrónicos a los deudores.',
  PAYMENT_ACCOUNTS: 'Cuentas de pago',
  CREATE_PAYMENT_ACCOUNT: 'Crear cuenta de pago',
  EDIT_PAYMENT_ACCOUNT: 'Editar cuenta de pago',
  EDIT_PAYMENT_ACCOUNT_SUCCESS: 'Cuenta de pago editada con éxito',
  DELETE_PAYMENT_ACCOUNT: '¿Estás seguro de que quieres eliminar esta cuenta de pago?',
  DELETE_PAYMENT_ACCOUNT_SUCCESS: 'Cuenta de pago eliminada con éxito',
  // END Fabian

  // BEGIN Patricio
  FACTOR_ACCRUED_INTEREST: 'Intereses devengados de autocarga',
  FACTOR_ACRRUED_FEES_FRECUENCY: 'Frecuencia de cuotas devengadas',
  PAYMENT_BATCH_CODE: 'Código de lote de pago',
  DEFAULT_REMITTANCE_CLIENT_TEMPLATE: 'Plantilla de remesas de clientes',
  DEFAULT_REMITTANCE_TEMPLATE_DESCRIPTION: 'Plantilla de remesas para enviar correos electrónicos a los clientes.',
  DEFAULT_STATEMENT_TEMPLATE: 'Plantilla de estado de cuenta',
  DEFAULT_STATEMENT_TEMPLATE_DESCRIPTION: 'Plantilla de correo electrónico para las declaraciones de deudores.',
  DEFAULT_REMITTANCE_BROKER_TEMPLATE: 'Plantilla de remesas de corredores',
  DEFAULT_REMITTANCE_BROKER_TEMPLATE_DESCRIPTION: 'Plantilla de remesas para enviar correos electrónicos a corredores.',
  DEFAULT_REMITTANCE_VENDOR_TEMPLATE: 'Plantilla de remesas de proveedores',
  DEFAULT_REMITTANCE_VENDOR_TEMPLATE_DESCRIPTION:
    'Plantilla de remesas para enviar correos electrónicos a proveedores.',

  EMAIL_CC_BCC: 'Correo electrónico CC/BCC',
  TITLE_CC_BCC_SECTION: 'CC.BCC para correo electrónico',
  TITLE_CREATE_CC_BCC: 'Crear Nuevo',
  TITLE_EDIT_CC_BCC: 'Editar CC/BCC',
  DELETE_CC_BBC_EMAIL: '¿Estás seguro de que quieres eliminar este correo electrónico?',
  DELETE_CC_BCC_EMAIL_SUCCESS: 'Correo electrónico eliminado con éxito',
  CREATE_CC_BCC_EMAIL_SUCCESS: 'Correo electrónico creado con éxito',
  EDIT_CC_BCC_EMAIL_SUCCESS: 'Correo electrónico editado con éxito',

  EMAIL_CC: 'CC',
  TOOLTIP_EDIT_EMAIL_CC: 'Editar Email CC',
  TOOLTIP_DELETE_EMAIL_CC: 'Eliminar Email CC',

  EMAIL_BCC: 'BCC',
  TOOLTIP_EDIT_EMAIL_BCC: 'Editar Email BCC',
  TOOLTIP_DELETE_EMAIL_BCC: 'Eliminar Email BCC',

  UPDATE_NOA_INVOICE_LEVEL: 'Actualizar la configuración del nivel de facturación de NOA.',
  NOA_INVOICE_LEVEL_SETTING: 'Configuración NOA Nivel Factura',
  NOA_INVOICE_LEVEL_INFORMATION: 'Información de nivel de NOA',
  NOA_INVOICE_LEVEL_FACTOR_SIGNATURE: 'Firma de Factor',
  NOA_INVOICE_LEVEL_LEGAL_NOTICE: 'Aviso Legal',
  NOA_INVOICE_IMAGE_SIGNATURE_INFO: 'Puede cargar su firma como imagen o texto',
  NO_SIGNATURE_AVAILABLE: 'No hay firma disponible',
  IMAGE_SIGNATURE: 'Imagen de la firma',

  NOA_LEVEL: 'Nivel de NOA',
  CLIENT_LEVEL: 'Nivel de Cliente',
  INVOICE_LEVEL: 'Nivel de Factura',
  NOA_LEVEL_SETTING_TITLE: 'Configuración de nivel de NOA',
  NOA_LEVEL_SETTING_DESCRIPTION: 'Establecer la configuración predeterminada de NOA',
  NOA_INVOICE_LEVEL_FACTOR_CONFIGURATION_UPDATED_SUCCESSFULLY: '¡Configuración de nivel de NOA actualizada con éxito!',
  FACTOR_INFORMATION: 'Información del Factor',
  // END Patricio
  //BEGIN Esteban
  FACTOR_FUEL_ADVANCE: 'Habilitar avance por combustible',
  FACTOR_INTEREST_RATE_PRIME: 'Tasa Prime',
  FACTOR_INTEREST_RATE_SOFR: 'Tasa Sofr',
  FACTOR_INTEREST_RATE_LIBOR: 'Tasa Libor',
  FACTOR_TIMEZONE: 'Zona Horaria para el Factor',
  FACTOR_TIMEZONE_DESCRIPTION: 'Configuración de zona Horaria para el Factor',
  FACTOR_GROUP_SAME_DAY_INVOICES: 'Agrupar facturas del mismo día',
  LEDGER_EXPORT_NAME: 'Nombre de exportación de libro mayor',
  FACTOR_ID: 'ID del Factor',
  USER_ID: 'ID de Usuario',
  FIRTS_NAME: 'Primer Nombre',
  MIDDLE_NAME: 'Segundo Nombre',
  LAST_NAME: 'Apellido',
  //END Esteban
  FACTOR_ACCRUED_INTEREST_DESCRIPTION: 'Activar o desactivar la autocarga de intereses devengados',
}
