export const logsEn = {
  ERROR_LOG: 'Error Log',
  ERROR_LOGS: 'Error Logs',
  MESSAGE: 'Message',
  SERVICE: 'Service',
  ERROR_DESCRIPTION: 'Error Description',
}

export const logsEs: typeof logsEn = {
  ERROR_LOG: 'Registro de Errores',
  ERROR_LOGS: 'Registros de Errores',
  MESSAGE: 'Mensaje',
  SERVICE: 'Servicio',
  ERROR_DESCRIPTION: 'Descripción del Error',
}
