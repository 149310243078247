export const bypassCreditLimitEn = {
  BYPASS_CREDITLIMIT_HEADER: 'User allowed to bypass credit limit:',
  BYPASS_HYPHEN: '- ',
  BYPASS_CLIENT: 'Client ',
  BYPASS_BROKER: 'Broker ',
  BYPASS_DEBTOR: 'Debtor ',
  BYPASS_CREDIT_LIMIT: ' with Credit Limit: ',
  BYPASS_CLIENT_DEBTOR_CREDIT_LIMIT: 'Client Debtor Credit Limit: ',
  BYPASS_BROKER_DEBTOR_CREDIT_LIMIT: 'Broker Debtor Credit Limit: ',
  BYPASS_NEW_APPROVED_AMOUNT: ', is going to have a new approved amount of: ',
  BYPASS_AND: ' and ',
  BYPASS_RELATIONSHIP: 'The relationship between ',
}

export const bypassCreditLimitEs: typeof bypassCreditLimitEn = {
  BYPASS_CREDITLIMIT_HEADER: 'Usuario permitido para omitir el límite de crédito:',
  BYPASS_HYPHEN: '- ',
  BYPASS_CLIENT: 'Cliente ',
  BYPASS_BROKER: 'Corredor ',
  BYPASS_DEBTOR: 'Deudor ',
  BYPASS_CREDIT_LIMIT: ' con Límite de Crédito: ',
  BYPASS_CLIENT_DEBTOR_CREDIT_LIMIT: 'Límite de crédito del cliente deudor: ',
  BYPASS_BROKER_DEBTOR_CREDIT_LIMIT: 'Límite de crédito del corredor deudor: ',
  BYPASS_NEW_APPROVED_AMOUNT: ', va a tener un nuevo monto aprobado de: ',
  BYPASS_AND: ' y ',
  BYPASS_RELATIONSHIP: 'La relación entre ',
}
