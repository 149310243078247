export const creditRequestEn = {
  //BEGIN Michael
  CREDIT_EXPIRATION: 'Credit Expiration',
  UNIT_TIME: 'Unit Time',
  PROVINCE: 'Province',
  REGION: 'Region',
  DISTRICT: 'District',
  REQUESTED_CREDIT_LIMIT: 'Requested Credit Limit',
  REQUESTED_AMOUNT: 'Requested Amount',
  REQUESTED_RELATIONSHIP_CREDIT_LIMIT: 'Requested Relationship Credit Limit',
  //END Michael
  CREDIT_REQUEST: 'Credit Request',
  CREDIT_CHECK: 'Credit Check',
  DEBTORS_REQUEST: 'Debtor Request',
  INTERNAL_CREDIT_REQUEST: 'Internal Credit Request',
  CREDIT_EXP: 'Credit Exp',
  USERNAME: 'Username',
  REQUESTED_BY: 'Requested By',
  REQUESTED_ON: 'Requested On',
  UPDATED_BY: 'Updated By',
  DEBTOR_NAME: 'Debtor Name',
  CREDIT_REQUEST_INFORMATION: 'Credit Request Information',
  DEBTOR_REQUEST_INFORMATION: 'Debtor Request Information',
  INTERNAL_CREDIT_UPDATE_REQUEST_INFORMATION: 'Internal Credit Update Request',
  APPROVE_REQUEST: 'Approve Request',
  THE_REQUEST_WAS_APPROVED_SUCCESSFULLY: 'The Credit Request was approved successfully',
  THE_REQUEST_WAS_DENIED_SUCCESSFULLY: 'The Credit Request was denied successfully',
  ERROR_UPDATING_CREDIT_REQUEST: 'Error updating credit request',
  COUNTRY: 'Country',
  STATE: 'State',
  CITY: 'City',
  ZIP: 'Zip',
  EMAIL: 'Email',
  PHONE: 'Phone',
  CLIENT: 'Client',
  CLIENT_INFORMATION: 'Client Information',
  BROKER: 'Broker',
  BROKER_INFORMATION: 'Broker Information',
  DEBTOR: 'Debtor',
  DEBTOR_INFORMATION: 'Debtor Information',

  REQUIRES_ADMIN_APPROVE: 'An Admin has to approve these changes',
  REQUIRES_ADMIN_APPROVE_DETAIL:
    'These changes will not appear in the credit information section until an Admin user approves it',
  OUTDATED_REQUEST: 'Outdated Request',
  OUTDATED_CREDIT_UPDATE_REQUEST_DESCRIPTION:
    'This request is outdated because the credit information has been updated since this request was made, a new request is required',
  CHANGES_REQUESTED: 'Changes Requested',
  UPDATE_INTERNAL_CREDIT_REQUEST_WARNING:
    'By approving this request, all pending requests for this company will be marked as outdated',
}

export const creditRequestEs: typeof creditRequestEn = {
  //BEGIN Michael
  CREDIT_EXPIRATION: 'Vencimiento de Crédito',
  UNIT_TIME: 'Unidad de Tiempo',
  PROVINCE: 'Provincia',
  REGION: 'Región',
  DISTRICT: 'Distrito',
  REQUESTED_CREDIT_LIMIT: 'Límite de Crédito Solicitado',
  REQUESTED_AMOUNT: 'Monto Solicitado',
  REQUESTED_RELATIONSHIP_CREDIT_LIMIT: 'Límite de Crédito de Relación Solicitado',
  //END Michael
  CREDIT_REQUEST: 'Solicitud de Crédito',
  CREDIT_CHECK: 'Verificación de Crédito',
  DEBTORS_REQUEST: 'Solicitud de Deudor',
  INTERNAL_CREDIT_REQUEST: 'Solicitud Interna de Crédito',
  CREDIT_EXP: 'Venc. Crédito',
  USERNAME: 'Nombre de Usuario',
  REQUESTED_BY: 'Solicitado Por',
  REQUESTED_ON: 'Solicitado En',
  UPDATED_BY: 'Actualizado Por',
  DEBTOR_NAME: 'Nombre del Deudor',
  CREDIT_REQUEST_INFORMATION: 'Información de Solicitud de Crédito',
  DEBTOR_REQUEST_INFORMATION: 'Información de Solicitud de Deudor',
  INTERNAL_CREDIT_UPDATE_REQUEST_INFORMATION: 'Solicitud Interna de Actualización de Crédito',
  APPROVE_REQUEST: 'Aprobar Solicitud',
  THE_REQUEST_WAS_APPROVED_SUCCESSFULLY: 'La solicitud fue aprobada exitosamente',
  THE_REQUEST_WAS_DENIED_SUCCESSFULLY: 'La solicitud fue denegada exitosamente',
  ERROR_UPDATING_CREDIT_REQUEST: 'Error actualizando la solicitud de crédito',
  COUNTRY: 'País',
  STATE: 'Estado',
  CITY: 'Ciudad',
  ZIP: 'Código Postal',
  EMAIL: 'Correo Electrónico',
  PHONE: 'Teléfono',
  CLIENT: 'Cliente',
  CLIENT_INFORMATION: 'Información del Cliente',
  BROKER: 'Corredor',
  BROKER_INFORMATION: 'Información del Corredor',
  DEBTOR: 'Deudor',
  DEBTOR_INFORMATION: 'Información del Deudor',

  REQUIRES_ADMIN_APPROVE: 'Un administrador debe aprobar estos cambios',
  REQUIRES_ADMIN_APPROVE_DETAIL:
    'Estos cambios no aparecerán en la sección de información de crédito hasta que un usuario administrador los apruebe',
  OUTDATED_REQUEST: 'Solicitud Desactualizada',
  OUTDATED_CREDIT_UPDATE_REQUEST_DESCRIPTION:
    'Esta solicitud está obsoleta porque la información de crédito ha sido actualizada desde que se solicitaron estos cambios, se requiere crear una nueva solicitud',
  CHANGES_REQUESTED: 'Cambios Solicitados',
  UPDATE_INTERNAL_CREDIT_REQUEST_WARNING:
    'Al aprobar esta solicitud, todas las solicitudes pendientes para esta empresa serán marcadas como desactualizadas',
}
