import request from 'tools/request'
import {
  FactorSettingsResponse,
  FactorCollectionResponse,
  FactorSettingRequest,
  WhiteLabelParams,
  AccountCollectionResponse,
  CreateFactorAccountRequest,
  CcBccEmailResponse,
  UpdateFactorCcBccRequest,
  FeatureFlagsResponse,
  UpdateFactorPaymentAccountsRequest,
  FactorConfigurationResponse,
  FactorConfigurationRequest,
  FactorTimezoneResponse,
} from 'types/factor'

// ========================= GETs ==========================

export const getWhiteLabel = async (params: WhiteLabelParams) => {
  const data = await request
    .get<FactorCollectionResponse>(`/factors/whitelabels`, {
      params: params,
    })
    .then((res) => {
      return res.data.factors
    })
  return data[0]
}

export const getMeFactorSettings = async () => {
  const data = await request.get<FactorSettingsResponse>(`/factors/me/settings`).then((res) => res.data)
  return data
}

export const getMeFactorTimezone = async () => {
  const data = await request.get<FactorTimezoneResponse>(`/factors/me/timezone`).then((res) => res.data)
  return data
}

export const getFactorAccounts = async () => {
  const data = await request.get<AccountCollectionResponse>(`/factors/me/accounts`).then((res) => res.data.accounts)
  return data
}

export const getFeatureFlags = async () => {
  const data = await request.get<FeatureFlagsResponse>(`/feature/feature-flags`).then((res) => res.data)
  return data
}

export const getMeFactorConfiguration = async () => {
  const data = await request.get<FactorConfigurationResponse>(`/factors/me/configuration`).then((res) => res.data)
  return data
}

// ========================= POSTs ==========================

export const createFactorAccount = async (payload: CreateFactorAccountRequest) => {
  const data = await request
    .post<AccountCollectionResponse>(`/factors/me/accounts`, payload)
    .then((res) => res.data.accounts)
  return data
}

// ========================= PUTs ==========================

export const updateMeCcBccFactorEmails = async (payload: UpdateFactorCcBccRequest) => {
  const data = await request.put<CcBccEmailResponse>(`/factors/me/cc-bcc`, payload).then((r) => r.data)
  return data
}

export const updateMeFactorSettings = async (payload: FactorSettingRequest) => {
  const data = await request.put<FactorSettingsResponse>(`/factors/me/settings`, payload).then((r) => r.data)
  return data
}

export const updatePaymentAccounts = async (payload: UpdateFactorPaymentAccountsRequest) => {
  const data = await request
    .put<UpdateFactorPaymentAccountsRequest>(`/factors/me/payment-accounts`, payload)
    .then((r) => r.data)
  return data
}

export const updateFactorConfiguration = async (payload: FactorConfigurationRequest) => {
  const data = await request.put<FactorConfigurationResponse>(`/factors/me/configuration`, payload).then((r) => r.data)
  return data
}

export const updateFactorImageSignature = async (file: File) => {
  const formData = new FormData()
  formData.append('file', file)
  const data = await request
    .put<FactorConfigurationResponse>(`/factors/me/configuration/signature`, formData)
    .then((r) => r.data)
  return data
}

export const deleteImageSignature = async () => {
  const data = await request
    .delete<FactorConfigurationResponse>(`/factors/me/configuration/signature`)
    .then((r) => r.data)
  return data
}
