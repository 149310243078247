export const qkBaseFactor = ['factors']

//getWhiteLabel
export const qkGetWhiteLabel = () => [...qkBaseFactor, 'whiteLabels']

//getFactorSettings
export const qkGetFactorSettings = (factorId?: string | null) => [...qkBaseFactor, 'settings', factorId]

export const qkGetFactorMeTimezone = () => [...qkBaseFactor, 'me', 'timezone']

//getFactorAccounts
export const qkGetFactorAccounts = () => [...qkBaseFactor, 'me', 'accounts']

//getFeatureFlags
export const qkGetFeatureFlags = () => [...qkBaseFactor, 'feature-flags']

//getFactorConfiguration
export const qkGetFactorConfiguration = () => [...qkBaseFactor, 'me', 'configuration']
