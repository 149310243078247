import { Button } from 'components/form'
import { ToolbarButton } from './DataTable'

type TableActionButtonProps = {
  actionButton: ToolbarButton
  className?: string
}

export const TableActionButton = (props: TableActionButtonProps) => {
  const { actionButton, className } = props
  return (
    <Button
      id={actionButton?.id}
      label={actionButton?.label}
      variant={actionButton?.variant}
      onClick={actionButton?.onClick}
      className={className}
      tooltip={actionButton?.tooltip}
      tooltipClassName={actionButton?.tooltipClassName}
      disabled={actionButton?.disabled}
      color={actionButton?.color}
      tooltipBackGroundColor={actionButton?.tooltipBackGroundColor}
      tooltipTextColor={actionButton?.tooltipTextColor}
      tooltipArrowColor={actionButton?.tooltipArrowColor}
      tooltipMaxWidth={actionButton?.tooltipMaxWidth}
    />
  )
}
